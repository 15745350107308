<template>
  <div class="theme-main-menu sticky-menu theme-menu-five">
    <div class="d-flex align-items-center justify-content-center">
      <div class="logo pointer" v-on:click="scrollToTop">
        <router-link :to="{ name: 'business' }">
        <img data-name="Ingomu-(@ingomu)" src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/Ingomu-(@ingomu).png" alt="">
      </router-link>
      </div>

      <nav id="mega-menu-holder" class="navbar navbar-expand-lg">
        <div class="nav-container">
          <button class="navbar-toggler">
            <span></span>
          </button>
          <div class="navbar-collapse collapse" id="navbarSupportedContent">
            <div class="d-lg-flex justify-content-between align-items-center">
              <ul class="navbar-nav main-side-nav font-gordita" id="one-page-nav">
                <li class="nav-item">
                  <router-link :to="{ name: 'business', hash: '#getstarted' }" @click.native="scrollTo('getstarted')"
                    class="nav-link">
                    ABOUT
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'business', hash: '#getdays' }" @click.native="scrollTo('getdays')"
                    class="nav-link">
                    GETTING STARTED
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'business', hash: '#features' }" @click.native="scrollTo('features')"
                    class="nav-link">
                    FEATURES
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'newsletter' }" class="nav-link">
                    NEWSLETTER
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'business', hash: '#faq' }" @click.native="scrollTo('features')"
                    class="nav-link">
                    FAQ
                  </router-link>
                </li>
                <!-- <li class="nav-item">
                  <router-link :to="{ name: 'blog' }" class="nav-link" :class="{ 'text-white': isDark }">
                    BLOG
                  </router-link>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div class="right-widget">
        <a href="#" data-toggle="modal" data-target="#contactModal" class="demo-button bluebutton color-white"><span>LET'S TALK</span></a>
      </div>
    </div>
  </div> <!-- /.theme-main-menu -->
</template>
    
<script>
const TIMEOUT = 1;

export default {
  name: 'EnterpriseHeader',
  props: {
    pageSchema: {
      type: String,
      default: 'light'
    }
  },
  data() {
    return {
      schema: this.pageSchema
    }
  },
  computed: {
    isDark() {
      return (this.schema == 'light') ? false : true
    }
  },
  mounted() {
    if (this.$route.hash) {
      setTimeout(() => {
        if (window.location.hash === this.$route.hash) {
          const el = document.getElementById(this.$route.hash.slice(1))
          if (el) {
            window.scrollTo(0, el.offsetTop + 200)
          }
        }
      }, TIMEOUT)
    }
  },
  methods: {
    scrollTo(hashtag) {
      document.getElementById(hashtag).scrollIntoView({ behavior: 'smooth' })
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>
<style scoped>
.pointer {
  cursor: pointer
}

@media (min-width: 1371px) {
    .navbar-expand-lg .navbar-toggler {
        display: none !important;
    }
}

@media (max-width: 1370px) {
    .navbar-expand-lg .navbar-toggler {
        display: block !important;
    }
}

@media (max-width: 1370px) {
    .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: column;
        flex-direction: column !important;
    }
}

@media (max-width: 575px) {
  .theme-menu-five .right-widget .demo-button, .theme-menu-five .right-widget .demo-button span, .theme-menu-five .right-widget .demo-button img { 
    display: block;
  }
  .theme-menu-five .right-widget .demo-button {
    line-height: 48px;
    font-size: 15px;
    width: 160px;
    padding: 0;
  }
}

.bluebutton {
	background: #3b5998;
	border: none !important;
}

.bluebutton:hover {
	background-color: #46c346 !important;
}

.color-white {
	color: #fff !important;
}
</style>
  